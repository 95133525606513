export default class Pubsub {
	constructor () {
		this.listeners = {};
		this.tokenId = -1;
	}

	_listenerHasTopic(topic) {
		for (var _key in this.listeners){
			if (this.listeners.hasOwnProperty(topic)){
				return true;
			}
		}
		return;
	}

	// debug
	_showListeners(topic) {
		if(topic) {
			return this.listeners[topic] || [];
		}
		return this.listeners;
	}

	publish(topic, ...data) {
		if(this._listenerHasTopic(topic) && this.listeners[topic].length) {
			this.listeners[topic].forEach(({callback, once, token}) => {
				if(typeof callback === 'function') {
					callback(...data);
					if(once) {
						this.unsubscribe(topic, token)
					}
				} 
			});
		}	
	}

	publishDelayed(topic, data, delay = 200, timeout = 5000) {
		let waitForSubscribe;
		const cancelPublish = setTimeout(() => {
			clearInterval(waitForSubscribe);
		}, timeout);
		const checkForSubscription = () => {
			if (this._listenerHasTopic(topic)) {
				clearTimeout(cancelPublish);
				clearInterval(waitForSubscribe);
				this.publish(topic, data);
			}
		};
		waitForSubscribe = setInterval(checkForSubscription, delay);
	}

	subscribe(topic, callback, once = false) {
		if(!this._listenerHasTopic(topic)) {
			this.listeners[topic] = [];
		}
		const token = String(++this.tokenId);
		this.listeners[topic].push({
			token,
			callback, 
			once
		});
		return {unsubscribe: this.unsubscribe.bind(this, topic, token)};
	}

	subscribeOnce(topic, callback) {
		return this.subscribe(topic, callback, true);
	}

	unsubscribe(topic, tkn) {
		if(!tkn && this._listenerHasTopic(topic)) {
			delete this.listeners[topic];
			return;
		}
		this.listeners[topic] = this.listeners[topic].filter(({token}) => token !== tkn);
	}
}
